<template>
  <div class="market-layout">
    <b-row class="no-gutters">
      <b-col lg="8" class="col">
        <sub-header type="market" />

        <b-row class="no-gutters">
          <b-col cols="12" md="4" class="instruments-bar-wrapper" :class="{'d-none': this.$route.name !== `${market.slug}.instruments`}">
            <instruments-bar />
          </b-col>

          <b-col class="content" :class="{'no-instruments-bar': this.$route.name !== `${market.slug}.instruments`}">
            <router-view />
          </b-col>
        </b-row>
      </b-col>

      <b-col class="intraday" :class="{'intraday-mobile-open': !isMobileMenuClosed}">
        <div>
          <b-tabs>
            <b-tab active v-if="!market.disable_intraday_updates">
              <template v-slot:title>
                <h4 class="d-none d-lg-block">
                  <img :src="user_theme ? intraday_image_dark : intraday_image" alt="Intraday Updates"> {{ $t('layouts.default.intraday_updates') }}
                </h4>

                <h4 class="d-block d-lg-none">
                  <div class="intraday-updates-icon-wrapper">
                    <img :src="user_theme ? intraday_image_dark : intraday_image" alt="Intraday Updates" @click="open_intraday_updates_mobile_menu()">
                    <div class="unread-intraday-updates"></div>
                  </div>
                </h4>

              </template>

              <intraday-updates-list :isMobileMenuOpen="isMobileMenuClosed" @close-updates-mobile-menu="close_intraday_updates_mobile_menu()"/>
            </b-tab>

            <b-tab v-if="!disabled_reports">
              <template v-slot:title>
                <h4 class="d-none d-lg-block">
                  <img width="14px" :src="user_theme ? report_image_dark : report_image" alt="Reports"> {{ $t('layouts.default.reports') }}
                </h4>

                <h4 class="d-block d-lg-none">
                  <div class="intraday-updates-icon-wrapper">
                    <img class="p-0" width="14px" :src="user_theme ? report_image_dark : report_image" alt="Reports" @click="open_intraday_updates_mobile_menu()">
                  </div>
                </h4>

              </template>

              <reports-list :isMobileMenuOpen="isMobileMenuClosed" @close-updates-mobile-menu="close_intraday_updates_mobile_menu()"/>
            </b-tab>

            <b-tab content-class="d-block d-lg-none slack-tab" :title="$t('layouts.default.slack_channel')" v-if="!market.disable_slack">
              <p class="text-center m-5 slack">
                <a :href="slack_workspace_url" target="_blank"><i class="fab fa-slack"></i></a>
                <br>
                {{ $t('layouts.default.open_slack') }}
              </p>
              <div class="slack-dynamic-content text-center"
                   v-for="dynamic_content in slack_dynamic_contents"
                   v-bind:key="dynamic_content.id"
              >
                <h4>{{ dynamic_content.title }}</h4>
                <div v-html="dynamic_content.content"></div>
                <img v-if="dynamic_content.image_url"
                     :src="dynamic_content.image_url"
                     class="img-fluid"
                     alt="content"
                />
              </div>
            </b-tab>

          </b-tabs>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SubHeader from '../../components/SubHeader.vue'
import InstrumentsBar from '../../components/market/InstrumentsBar.vue'
import IntradayUpdatesList from '../../components/intraday_updates/List.vue'
import ReportsList from '../../components/reports/List.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MarketLayout',
  components: {
    SubHeader,
    InstrumentsBar,
    IntradayUpdatesList,
    ReportsList
  },
  data () {
    return {
      isMobileMenuClosed: true,
      slack_workspace_url: process.env.VUE_APP_SLACK_WORKSPACE_URL,
      market: Object,
      intraday_image: '/img/intraday.svg',
      intraday_image_dark: '/img/intraday-dark.svg',
      report_image: '/img/report.svg',
      report_image_dark: '/img/report-dark.svg',
      disabled_reports: true
    }
  },
  computed: {
    ...mapState({
      current_user: state => state.profile.account.current_user,
      user_theme: state => state.user_theme.user_theme,
      markets: state => state.market.markets
    }),
    ...mapGetters({
      slack_dynamic_contents: 'dynamic_contents/slack_dynamic_contents'
    })
  },
  mounted () {
    this.$store.dispatch('dynamic_contents/load_dynamic_contents')
    const that = this
    this.$store.dispatch('market/loadMarkets').then(() => {
      that.market = that.markets.find(market => market.slug === that.$route.matched[0].meta.market)
      that.disabled_reports = that.market.disable_reports
    })
  },
  methods: {
    open_intraday_updates_mobile_menu () {
      this.isMobileMenuClosed = false
    },
    close_intraday_updates_mobile_menu () {
      this.isMobileMenuClosed = true
    }
  }
}
</script>

<style scoped lang="scss">
.market-layout {
  // @include layout-with-sticky-header;
  background-color: var(--white-black);
}

.instruments-bar-wrapper {
  max-width: 150px;
  height: calc(100vh - 140px);
  overflow-y: auto;

  @media (max-width: 575.98px) {
    width: 100%;
    max-width: 100%;
  }
}

.content {
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 991.98px) {
    position: fixed;
    width: calc(100% - 150px);
    z-index: 100;
    background-color: var(--white-black);
    left: 150px;
    top: 140px;
    bottom: 0;

    &.no-instruments-bar {
      width: 100%;
      left: 0;
    }
  }

  @media (max-width: 575.98px) {
    width: 100%;
    left: 0;
  }
}

.intraday {
  background-color: var(--pale-grey-two-charcoal-grey-two);
  z-index: 1;
  border-left: 1px solid var(--ice-blue-charcoal-grey-three);

  &.intraday-mobile-open {
    z-index: 300;
  }

  ::v-deep .nav-tabs {
    border-bottom: 1px solid var(--ice-blue-charcoal-grey-three);
    line-height: 70px;

    .nav-item {
      width: 50%;
      text-align: center;
      margin-bottom: 0;
    }

    .nav-link {
      color: var(--muted-blue-dark-sky-blue);
      font-size: 14px;
      border: 0;
      border-radius: 0;
      padding: 0;
      position: relative;

      h4 {
        font-size: 14px;
        line-height: inherit;
        margin-bottom: 0;
      }
      .intraday-updates-icon-wrapper {
        width: 50px;
        margin: 0 auto;
        position: relative;
      }

      .unread-intraday-updates {
        width: 6px;
        height: 6px;
        background-color: var(--coral-pink);
        border-radius: 100%;
        top: 20px;
        right: 5px;
        position: absolute;
      }

      &.active {
        background-color: initial;
        color: var(--muted-blue-dark-sky-blue);
      }

      &:before,
      &:after {
        position: absolute;
        transition: all 0.35s ease;
      }

      &:after {
        bottom: 0;
        left: 0;
        display: block;
        height: 3px;
        width: 0%;
        content: '';
        background-color: var(--muted-blue-dark-sky-blue);
      }

      &:hover:after,
      &.active:after {
        opacity: 1;
        width: 100%;
      }

      &:hover:after,
      &.active:after {
        max-width: 100%;
      }
    }

    @media (max-width: 991.98px) {
      .nav-item {
        width: 100%;

        img {
          padding: 18px;
        }

        &.slack-tab {
          display: none;
        }

        .nav-link:after {
          width: 0;
        }
      }
    }
  }

  ::v-deep .tab-content {
    height: calc(100vh - 140px);
    overflow-y: auto;
  }
}

.slack {
  a {
    font-size: 50px;
  }
}

.slack-dynamic-content {
  padding: 25px 25px 9px;
  border-top: 1px solid var(--ice-blue-three-charcoal-grey-three);

  h4 {
    color: var(--blueberry);
    margin-bottom: 20px;
    font-size: 20px;
  }

  div {
    font-size: 13px;
  }
}

@media (max-width: 991.98px) {
  .intraday.col {
    flex: 0 0 70px;
  }
}
</style>
